/* eslint-disable complexity */
import React, { Fragment } from 'react';
import CollapseWrapper from '../../shared/CollapseWrapper';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import _get from 'lodash/get';
import {
  ComercialContractStrata
} from '../../../constants/enums';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import collapseWrapperStyles from '../../../styles/components/collapseWrapper';

import StatusLabel from './Status/StatusLabel';

import moment from 'moment-timezone';

const styles = theme => ({
  itemValue: {
    textTransform: 'lowercase',
    '&:first-letter': {
      textTransform: 'uppercase'
    }
  },
  nameValue: {
    textTransform: 'capitalize'
  },
  dashedDivider: {
    margin: [[theme.spacing.unit * 2, 0]],
    borderTop: 'dashed 1px rgba(0, 0, 0, 0.12)'
  }
});

const _name = (customer, customerDetails) => {
  const fullname = `${customerDetails.name ||
    customer.name ||
    '--'} ${customerDetails.lastName || customer.lastName || '--'}`;

  return fullname.toLowerCase();
};

const _gender = (customer, customerDetails) => {
  const gender = customerDetails.gender || customer.gender;
  if (!gender) {
    return '--';
  }
  if (gender === 'M') return 'Masculino';
  if (gender === 'F') return 'Femenino';
  return gender ? gender : '--';
};

const _cause = (reasonSolidaryDebtor,solidaryDebtorCausesEnum) => {
  if (!reasonSolidaryDebtor) {
    return '--';
  }
  return _get(
    solidaryDebtorCausesEnum.find(
      solidaryDebtorCause =>
        solidaryDebtorCause.id.toString() === reasonSolidaryDebtor.toString()
    ),
    'name',
    '--'
  );
};

const _studyLevels = customerDetails => {
  const levelOfStudies = customerDetails.levelOfStudies;
  if (!levelOfStudies) {
    return '--';
  }
  return levelOfStudies;
};

const _getAddressInfo = (contract, customerDetails) => {
  return {
    address: _get(contract, 'address') || _get(customerDetails, 'address.body'),
    neighborhood:
      _get(contract, 'neighborhood.description') ||
      _get(customerDetails, 'address.neighborhood'),
    location:
      _get(contract, 'location.description') ||
      _get(customerDetails, 'address.location'),
    state:
      _get(contract, 'state.description') ||
      _get(customerDetails, 'address.department')
  };
};

const _address = (contract, customerDetails) =>
  _getAddressInfo(contract, customerDetails).address;

const _neighborhood = (contract, customerDetails) =>
  _getAddressInfo(contract, customerDetails).neighborhood;

const _location = (contract, customerDetails) =>
  _getAddressInfo(contract, customerDetails).location;

const _state = (contract, customerDetails) =>
  _getAddressInfo(contract, customerDetails).state;

export const Customer = ({
  classes,
  type = 'Deudor',
  customer,
  contract,
  customerDetails,
  reasonSolidaryDebtor,
  expanded = false,
  status,
  solidaryDebtorCauses = []
}) => (
  <CollapseWrapper
    expanded={expanded}
    classes={{
      root: classes.root,
      header: classes.panelHeader,
      expandButton: classes.expandButton,
      bodyRoot: classes.collapsePanelBodyRoot
    }}
    header={() => (
      <div className={classes.titleWrapper}>
        <h2 className={classnames(classes.headline8, classes.title)}>{type}</h2>
        {status && <StatusLabel label={status} type={status} />}
      </div>
    )}
    body={
      <Fragment>
        <Divider />
        <div className={classes.body}>
          {reasonSolidaryDebtor && (
            <Fragment>
              <Grid container spacing={16}>
                <Grid item lg={12}>
                  <p className={classnames(classes.subtitle3)}>Motivo:</p>
                  <p className={classnames(classes.body2, classes.itemValue)}>
                    {_cause(reasonSolidaryDebtor,solidaryDebtorCauses)}
                  </p>
                </Grid>
              </Grid>
              <Divider classes={{ root: classes.dashedDivider }} />
            </Fragment>
          )}
          <Grid container spacing={16}>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>Nombre:</p>
              <p className={classnames(classes.body2, classes.nameValue)}>
                {_name(customer, customerDetails)}
              </p>
            </Grid>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>Tipo de Documento</p>
              <p className={classnames(classes.body2)}>
                {
                  customerDetails.typeOfIdentification ||
                  customer.typeOfIdentification ||
                  '--'
                }
              </p>
            </Grid>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>No. Documento</p>
              <p className={classnames(classes.body2)}>
                {
                  customerDetails.identification ||
                  customer.identification ||
                  '--'
                }
              </p>
            </Grid>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>
                Fecha de expedición:
              </p>
              <p className={classnames(classes.body2)}>
                {customerDetails.idExpeditionDate
                  ? moment(customerDetails.idExpeditionDate)
                      .locale('es')
                      .format('YYYY-MM-DD')
                  : '--'}
              </p>
            </Grid>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>
                Lugar de expedición:
              </p>
              <p className={classnames(classes.body2, classes.itemValue)}>
                {customerDetails.idExpeditionPlace || '--'}
              </p>
            </Grid>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>
                Fecha de nacimiento:
              </p>
              <p className={classnames(classes.body2)}>
                {customerDetails.dateOfBirth
                  ? moment(customerDetails.dateOfBirth)
                      .locale('es')
                      .format('YYYY-MM-DD')
                  : '--'}
              </p>
            </Grid>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>Género:</p>
              <p className={classnames(classes.body2, classes.itemValue)}>
                {_gender(customer, customerDetails)}
              </p>
            </Grid>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>Estado Civil:</p>
              <p className={classnames(classes.body2, classes.itemValue)}>
                {customerDetails.maritalStatus || '--'}
              </p>
            </Grid>
          </Grid>
          <Divider classes={{ root: classes.dashedDivider }} />
          <Grid container spacing={16}>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>Teléfono:</p>
              <p className={classnames(classes.body2)}>
                {customerDetails.phone || '--'}
              </p>
            </Grid>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>Celular:</p>
              <p className={classnames(classes.body2)}>
                {customerDetails.mobile || '--'}
              </p>
            </Grid>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>
                Correo eléctronico:
              </p>
              <p className={classnames(classes.body2)}>
                {customerDetails.email || '--'}
              </p>
            </Grid>
            {customerDetails.companyName && (
              <Grid item md={4}>
                <p className={classnames(classes.subtitle3)}>Empresa:</p>
                <p className={classnames(classes.body2, classes.itemValue)}>
                  {customerDetails.companyName}
                </p>
              </Grid>
            )}
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>Profesión:</p>
              <p className={classnames(classes.body2, classes.itemValue)}>
                {customerDetails.profession || '--'}
              </p>
            </Grid>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>Ocupación:</p>
              <p className={classnames(classes.body2, classes.itemValue)}>
                {customerDetails.occupation || '--'}
              </p>
            </Grid>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>
                Nivel de Estudios:
              </p>
              <p className={classnames(classes.body2, classes.itemValue)}>
                {_studyLevels(customerDetails)}
              </p>
            </Grid>
          </Grid>
          <Divider classes={{ root: classes.dashedDivider }} />
          <Grid container spacing={16}>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>
                Contrato:
              </p>
              <p className={classnames(classes.body2, classes.itemValue)}>
                {_get(contract, 'id') || '--'}
              </p>
            </Grid>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>
                Dirección de Residencia:
              </p>
              <p className={classnames(classes.body2, classes.itemValue)}>
                {_address(contract, customerDetails) || '--'}
              </p>
            </Grid>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>Barrio:</p>
              <p className={classnames(classes.body2, classes.itemValue)}>
                {_neighborhood(contract, customerDetails) || '--'}
              </p>
            </Grid>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>Ciudad:</p>
              <p className={classnames(classes.body2, classes.itemValue)}>
                {_location(contract, customerDetails) || '--'}
              </p>
            </Grid>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>Departamento:</p>
              <p className={classnames(classes.body2, classes.itemValue)}>
                {_state(contract, customerDetails) || '--'}
              </p>
            </Grid>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>Tipo de Vivienda:</p>
              <p className={classnames(classes.body2, classes.itemValue)}>
                {customerDetails.typeOfHouse || '--'}
              </p>
            </Grid>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>Estrato:</p>
              <p className={classnames(classes.body2)}>
                {contract.contractType.id == 2
                  ? ComercialContractStrata[contract.contractStratum.contractSubtypeId]
                  : customerDetails.socialStratus || '--'
                }
              </p>
            </Grid>
            <Grid item md={4}>
              <p className={classnames(classes.subtitle3)}>Titular:</p>
              <p className={classnames(classes.body2)}>
                {customerDetails.billOwner ? 'Si' : 'No'}
              </p>
            </Grid>
          </Grid>
        </div>
      </Fragment>
    }
  />
);
export default withStyles(
  combineStyles(
    styles,
    collapseWrapperStyles,
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles
  )
)(Customer);
